<script>
import MenuList from "../components/MenuList.vue";
import { ref, onMounted, onUnmounted, computed } from "vue";

export default {
  name: "App",
  components: {
    MenuList,
  },
  setup() {
    const windowWidth = window.innerWidth;
    const x = ref(0);

    const leftLooking = computed(() => x.value < windowWidth / 2);

    function update(event) {
      x.value = event.pageX;
    }

    onMounted(() => window.addEventListener("mousemove", update));
    onUnmounted(() => window.removeEventListener("mousemove", update));

    return {
      leftLooking,
    };
  },
};
</script>

<template>
  <v-container class="container">
    <img
      v-if="leftLooking"
      alt="Gozum Uzerinizde"
      src="../assets/header-logo-left.png"
    />
    <img v-else alt="Gozum Uzerinizde" src="../assets/header-logo-right.png" />
    <menu-list />
    <v-divider />

    <h1 class="pt-5">İletişim</h1>
    <div class="mt-9">
      İlla temasta bulunmak istiyorsanız, bu konuyu içinizde biraz daha
      derinlemesine düşünüp kendinize saklayınız. Teşekkürler.
    </div>
  </v-container>
</template>

<style></style>
