<script>
export default {
  name: "OnlineAlisveris",
};
</script>

<template>
  <v-row>
    <v-col class="mr-8">
      <div class="header" style="color: green">
        Online Market Alışverişi Sırasında Gelen Yavşaklık Hissi
      </div>
      <span class="text">
        Şerefsiz hissediyorum, kurye yaşlıysa daha da şerefsiz hissediyorum.
        Akşamın bir saati kek, bisküvi için tüketilen benzine, emeğe acıyorum.
        İnsanlık bu noktaya gelmemeliydi. Aşağı inip, yarım saat yürüyüp, bir
        büfeye girip ihtiyacını karşılayamayacak kadar aciz hale mi geldik?
        Sorarım sizlere! Nedir bu halimiz? Bu poşetler neden bu kadar kaliteli?
        Ben bu kaliteli poşetleri hakedecek ne yaptım? Evin her tarafı Getir,
        Banabi poşeti oldu. Eskiden kaliteli poşetin bile kıymeti bilinirdi.
        Yıllarca saklardık. Eczanelerin kaliteli ve örgülü poşetlerini
        büyüklerimiz ile beraber gömerdik. Onların kıymetlileri poşetleriydi.
        Poşetler onları bu dünyada taşımıştı, öldüklerinde de onlar poşetlerini
        taşırdı, tıpkı kefenleri gibi. Şimdi beni kocaman getir desenli bir
        kefen ile gömmenizi rica ediyorum. Ben bu poşetleri haketmedim. Ben bu
        keki haketmedim. Bana mutluluk falan getirmeyin, bir zahmet onu da gidip
        ben alayım.
      </span>
    </v-col>

    <v-col class="mt-5">
      <v-row>
        <img
          alt="Musteri Hizmetleri"
          src="../../assets/online-alisveris-1.png"
          style="height: 300px"
        />
      </v-row>
      <v-row class="mt-9">
        <img
          alt="Kaliteli Poset"
          src="../../assets/online-alisveris-2.png"
          style="height: 400px"
        />
      </v-row>

      <v-row>
        <div>Atın beni denizlere</div>
      </v-row>
    </v-col>
  </v-row>
</template>
