<script>
export default {
  name: "TopSakal",
};
</script>

<template>
  <v-row>
    <span class="text" style="color: green">
      Top sakal bir yüktür. Uzatana da, bakana da, dinleyicisine de… Yıllar yılı
      türk rock grupları, bu kıl yumağını elden ele dolaştırmışlardır. Bu çirkin
      sakalların sebebi ise, belki bazı çevrelerin baskısı belki de özgürce
      tartışma ortamının bir türlü ülkemizde yeterli seviyeye ulaşamamasından
      dolayı tartışılamamıştır. Benim eşten dosttan ve bulunduğum bazı gizli
      toplantılardan duyduğum olası sebepler ise şöyle;
    </span>
  </v-row>
  <v-row>
    <v-col>
      <img
        alt="Top 1"
        src="../../assets/top-sakal-1.png"
        style="height: 400px"
      />
    </v-col>
    <v-col>
      <v-row>
        <img
          alt="Top 2"
          src="../../assets/top-sakal-2.png"
          style="height: 200px"
        />
      </v-row>
      <v-row>
        <v-row>
          <img
            alt="Top 3"
            src="../../assets/top-sakal-3.png"
            style="height: 200px"
          />
        </v-row>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <ul class="text" style="color: green">
      <li>
        Top sakalın canlı bir varlık olması ve yaşamını sürdürmek için türk rock
        grubu solistine ihtiyaç duyması. Hikayeye göre top sakal kendini
        kaliteli bir kayıt stüdyosuna bırakıyor. Solistler stüdyoda kayıt
        esnasında mikrofonun yanında bulunan takma top sakalı denemek için
        takıyorlar ve daha sonra çıkaramıyorlar. (Albüm bitince sakal
        kendiliğinden düşüyor, başka gruplar sakalı deniyor bu şekilde sakal
        gruptan gruba geçerek yaşamayı sürdürüyor)
      </li>
      <li>
        Solisti kıskanan grup üyelerinin “solist sensen s.ke s.ke bu sakalı
        bırakacaksın” baskısı
      </li>
      <li>Rivayete göre top sakal müzisyenlere mistik bir güç sağlıyor</li>
      <li>Top sakal fetişisti plak şirketi sahipleri</li>
      <li>
        Top sakalın çene ve gırtlak bölgesini daima sıcak tutarak solistin ses
        kalitesini koruması
      </li>
    </ul>
  </v-row>
  <v-row>
    <span class="text" style="color: green">
      Gerçek nedenini asla bilemeyecek olsak da buradan türk rock gruplarına
      ingilizce sözlü şarkı yapmamaları yönünde ricada bulunuyoruz, teşekkürler.
    </span>
  </v-row>
</template>
