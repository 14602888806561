<script>
export default {
  name: "ErilDil",
};
</script>

<template>
  <v-row>
    <v-col class="mr-8">
      <div class="header" style="color: palevioletred">
        Eril Dilinizden Ameliyat İle Kurtulmanız Mümkün!
      </div>
      <span class="text">
        Eril dilinizin sebebi beyninize değil gerçekten de dilinize dayanıyor
        olabilir. Seksist bir arkadaşım vardı, dil bağını kestirdikten sonra çok
        daha kibar, halden anlayan bir insana dönüştü. Denemekte fayda var…
      </span>
    </v-col>

    <v-col class="mt-5">
      <v-row>
        <img
          alt="Eril Dil Ameliyatı"
          src="../../assets/eril-dil-1.png"
          style="height: 300px"
        />
      </v-row>
    </v-col>
  </v-row>

  <v-row class="mt-8">
    <v-col>
      <img alt="Rolling Stones" src="../../assets/eril-dil-2.png" />
    </v-col>
  </v-row>

  <v-row class="mt-4">
    <span class="text">
      Bu vesile ile rolling stones dili ve velvet underground muzu arasındaki
      ilginç benzerliğe dikkatinizi çekmek isterim. Hiçbir yönüyle
      benzememelerine karşın, birbirlerine çok benziyorlar.
    </span>
  </v-row>

  <v-row class="mt-3">
    <v-col>
      <img alt="Enteresan" src="../../assets/eril-dil-3.png" />
    </v-col>
  </v-row>
</template>
