<script setup>
import { ref, computed } from "vue";
import Home from "./pages/Home.vue";
import Contact from "./pages/Contact.vue";

const routes = {
  "/": Home,
  "/iletisim": Contact,
};

const currentPath = ref(window.location.hash);

window.addEventListener("hashchange", () => {
  currentPath.value = window.location.hash;
});

const currentView = computed(() => {
  return routes[currentPath.value.slice(1) || "/"] || Home;
});
</script>

<template>
  <v-container class="wrapper">
    <component :is="currentView" />
  </v-container>
</template>

<style>
body {
  background-color: #f9f62e;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 60px !important;
  padding-bottom: 60px !important;

  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.wrapper {
  width: 800px !important;
}

.header {
  font-weight: bold;
  font-size: 30px;
}

.text {
  font-weight: bold;
  font-size: 18px;
}
</style>
