<script>
import MenuList from "../components/MenuList.vue";
import OnlineAlisveris from "../components/articles/OnlineAlisveris.vue";
import ErilDil from "../components/articles/ErilDil.vue";
import PijamaAlti from "../components/articles/PijamaAlti.vue";
import TopSakal from "../components/articles/TopSakal.vue";
import OkuyucuKalitesi from "../components/articles/OkuyucuKalitesi.vue";
import NomadDoner from "../components/articles/NomadDoner.vue";
import { ref, onMounted, onUnmounted, computed } from "vue";

export default {
  name: "App",
  components: {
    MenuList,
    OnlineAlisveris,
    NomadDoner,
    OkuyucuKalitesi,
    ErilDil,
    PijamaAlti,
    TopSakal,
  },
  setup() {
    const windowWidth = window.innerWidth;
    const x = ref(0);

    const leftLooking = computed(() => x.value < windowWidth / 2);

    function update(event) {
      x.value = event.pageX;
    }

    onMounted(() => window.addEventListener("mousemove", update));
    onUnmounted(() => window.removeEventListener("mousemove", update));

    return {
      leftLooking,
    };
  },
};
</script>

<template>
  <img
    v-if="leftLooking"
    alt="Gozum Uzerinizde"
    src="../assets/header-logo-left.png"
  />
  <img v-else alt="Gozum Uzerinizde" src="../assets/header-logo-right.png" />

  <menu-list />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" />
  <online-alisveris />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" />
  <eril-dil />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" />
  <nomad-doner />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" />
  <okuyucu-kalitesi />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" />
  <pijama-alti />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" />
  <!-- <tehlikeli-oyunlar />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" /> -->
  <top-sakal />
  <v-divider class="mt-8 mb-8 border-opacity-50" color="green" :thickness="4" />
</template>
