<script>
export default {
  name: "PijamaAlti",
};
</script>

<template>
  <div
    class="header"
    align-items:center
    style="color: #50a6c5; text-align: center"
  >
    Ansızın Çıkagelen Çok Rahat Pijama Altı
  </div>
  <v-row>
    <v-col class="mr-1">
      <v-row>
        <img
          alt="Cok Guzel Pijama Alti"
          src="../../assets/pijama-alti-1.png"
          style="height: 500px"
        />
      </v-row>
      <v-row class="mt-9">
        <img
          alt="Goklerden Gelen"
          src="../../assets/pijama-alti-2.png"
          style="height: 200px"
        />
      </v-row>

      <v-row>
        <span style="color: blueviolet; font-size: 14px">
          Kirliyi giyme bunu giy (kafamdakileri görsele aktarmada yardım etmek
          için iletişim bölümünden bana ulaşmayı denerseniz başarısız
          olabilirsiniz ama yine de teşekkürler)
        </span>
      </v-row>

      <v-row>
        <img
          alt="Candan"
          src="../../assets/pijama-alti-3.png"
          style="height: 200px"
        />
      </v-row>
    </v-col>

    <v-col>
      <span class="text" style="color: blueviolet; letter-spacing: 1px">
        <br /><br /><br /><br />
        Temiz pijamalarımın bittiği, kendimden ve içinde bulunduğum yüzyılın
        imkanlarından utanarak kirlileri çoktan bir tur daha giyerek tükettiğim
        bir gündeydim. Duş almıştım. İlkel güdülerim duştan sonra temiz
        kıyafetler giymemi öneriyordu ve buna karşı gelecek halde değildim.
        Çaresizdim, perişan haldeydim.( Yazıyı bu noktaya kadar kalitesiz bir
        şarkı şiir karşımı şeklinde okuduysanız doğru yoldanız, burada uzun bir
        es verip devam edin )
        <br /><br /><br /><br />
        Kendime saygısızlıklar edecektim, kirlilerimi giyip kötü hissedecektim.
        Seni bana tanrı gönderdi, biliyorum. I am a believer, eski ama çok rahat
        pijama altı, I am a believer now…

        <br /><br /><br /><br /><br />
        Biliyorum sana sahip değildim. Seni gardırobun derinliklerine ben
        gömmedim. Hoşgeldin eski ama çok rahat pijama altı… Hoşgeldin…
      </span>
    </v-col>
  </v-row>
</template>
