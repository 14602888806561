<script>
export default {
  name: "OkuyucuKalitesi",
};
</script>

<template>
  <div
    class="header mb-5"
    align-items:center
    style="color: pink; text-align: center; background-color: white"
  >
    Okuyucularımız ne kadar kaliteli?
  </div>
  <v-row>
    <v-col>
      <v-row>
        <div>Aşağıdaki müzik türlerinden hangisi daha kalitelidir?</div>
      </v-row>

      <v-row class="mb-2 pt-2">
        <v-btn depressed color="primary"> Caz </v-btn>
      </v-row>
      <v-row>
        <v-btn depressed color="primary"> Diğer </v-btn>
      </v-row>
    </v-col>
    <v-col>
      <v-row>
        <div>Çoğunlukla hangi müzik türünü dinliyorsunuz?</div>
      </v-row>

      <v-row class="mb-2 pt-2">
        <v-btn depressed color="primary"> Caz </v-btn>
      </v-row>
      <v-row>
        <v-btn depressed color="primary"> Diğer </v-btn>
      </v-row>
    </v-col>
  </v-row>
  <div class="text mt-8" align-items:center>
    İki soruya da aynı cevabı vermediyseniz üzülmeyin, insanın kalitesi
    dinlediği müzik türüyle ölçülmez ama çok da kaliteli bir insan
    değilmişsiniz. Canınız sağolsun...
  </div>
</template>
