<script>
export default {
  name: "NomadDoner",
};
</script>

<template>
  <v-row>
    <v-col class="mr-8">
      <div class="header" style="color: green">Nomad Döner</div>
      <span class="text" style="color: pink; background-color: black">
        Almanyaya ben gidemedim,
        <br />
        okulda Mert diye bir çocuk vardı ortalaması iyi olan, o gitti. <br />
        Bir de tavuk döner gitti. <br />
        Merti iki sene sonra ziyarete geldiğinde gördüm. <br />
        Bambaşka biriydi. <br />
        Gülüşü değişmiş, teni ışıl ışıl parlıyordu. <br />
        Çok daha kaliteli bir insan olmuştu. <br />
        Kuru ve sade tavuk dönerle burada haftada 1-2 görüşüyoruz. <br />
        Onu seviyorum <br />
        Almanyaya giden tavuk döner ise Mert gibi daha kaliteli olmuş diye
        duydum. <br />
        3-4 farklı sos girmiş içine <br />
        Sebzeyi arttırmış, <br />
        hatta vegan olmayı düşünüyormuş <br />
        Kimse de yakasından tutup sen tavuk dönersin vegan olamazsın dememiş
        <br />
        Yavşak.. <br />
      </span>
    </v-col>

    <v-col class="mt-5">
      <v-row>
        <img
          alt="Berlin"
          src="../../assets/nomad-doner-1.png"
          style="height: 300px"
        />
      </v-row>
      <v-row class="mt-9">
        <img
          alt="Kaliteli Poset"
          src="../../assets/nomad-doner-2.png"
          style="height: 400px"
        />
      </v-row>
    </v-col>
  </v-row>
</template>
