<script>
export default {
  name: 'MenuList',
  props: {
    msg: String
  }
}
</script>



<template>
    <v-col class="text-right">
      <v-btn class="text-none" variant="text" href="#/"> Ana Sayfa </v-btn>
      <v-btn class="text-none" variant="text" href="#/iletisim"> İletişim </v-btn>
    </v-col>
</template>


<style>

</style>
